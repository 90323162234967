export default function reducer(state, action) {
    switch (action.type) {
      case "CORRECT":
        return { correct: state.correct + 1, answered: state.answered + 1 };
      case "INCORRECT":
        return { correct: state.correct, answered: state.answered + 1 };
      case "RESET":
        return {correct: null, answered: null};
      default:
        return state;
    }
  }