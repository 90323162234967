export async function articleGenitiveExplanationLoader() {
    const responseGeneralRules = await fetch(
      "/articleAndGenitiveCase/generalRules.html"
    );
    const generalRules = await responseGeneralRules.text();
  
    const responseFeminineRules = await fetch(
      "/articleAndGenitiveCase/feminineRules.html"
    );
    const feminineRules = await responseFeminineRules.text();
  
    const responseMasculineRules = await fetch(
      "/articleAndGenitiveCase/masculineRules.html"
    );
    const masculineRules = await responseMasculineRules.text();
  
    const data = { generalRules, feminineRules, masculineRules };
    return data;
  }
  