export async function articleNominativeExplanationLoader() {
  const responseGeneralRules = await fetch(
    "/definiteArticle/definiteArticleGeneral.html"
  );
  const generalRules = await responseGeneralRules.text();

  const responseFeminineRules = await fetch(
    "/definiteArticle/definiteArticleFem.html"
  );
  const feminineRules = await responseFeminineRules.text();

  const responseMasculineRules = await fetch(
    "/definiteArticle/definiteArticleMasc.html"
  );
  const masculineRules = await responseMasculineRules.text();

  const data = { generalRules, feminineRules, masculineRules };
  return data;
}
