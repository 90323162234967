import { json } from "react-router-dom";

export async function verbQuizLoader({ request }) {
  const url = new URL(request.url);

  const tenses = url.searchParams.has("tense")
    ? url.searchParams.getAll("tense")
    : ["pastTense"];
  const regularity = url.searchParams.has("regularity")
    ? url.searchParams.getAll("regularity")
    : ["regular"];

  const response = await fetch("/conjugationData.json"); //this is a response obj

  const j = await response.json(); //take the body of the rsponse and turn into js obj
  let verbs = j.verbs;
  let verbsList = [];

  for (let tense of tenses) {
    for (let key of regularity) {
      verbsList = [...verbsList, ...verbs[tense][key]];
    }
  }

  verbs = verbsList.sort((a, b) => {
    return Math.floor(Math.random() * 3) - 1;
  });

  const data = { verbs: verbs, url: url };
  return json(data, { status: 200 }); //response.json() extracts the body of response and turns it in js obj
  // return json is actually turning it into a response obj
}
