function AboutPage() {
  return (
    <div className="about-page-container center-content">
      <div className="nes-container is-rounded">
        <p>
          Bunaíodh an suíomh seo i 2023 chun tacú le foghlaimeoirí a gcuid
          gramadaí a fheabhsú. An aidhm atá leis an suíomh seo ná na rialacha
          gramadaí a shimpliú agus áit a chur ar fáil chun cleachtadh a dhéanamh
          ar na rialacha sin. Beidh an suíomh á fhorbairt agam amach as seo,
          agus tá súil agam níos mó gramadaí a chur leis! <br /> <br />Ba mhaith liom mo
          bhuíochas a ghabháil leis an gComhairle um Oideachas Gaeltachta agus
          Gaelscolaíochta as tacaíocht airgeadais a chur ar fáil chun an suíomh
          a chruthú agus a chur ar líne.<br /> <br />
          Ba mhaith liom mo bhuíochas a ghabháil leis an dearthóir iontach Laoise Tarrant as léarscáil álainn a tharraingt don suíomh.
        </p>
      </div>
    </div>
  );
}

export default AboutPage;
