function DropdownButton(props) {
  return (
    <div>
      <button
        style={{ width: "100%" }}
        className="nes-btn is-warning "
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {props.btnTitle}
      </button>
      <ul className="dropdown-menu">
        <li>
          <button
            className="dropdown-item"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${props.id}-1`}
            aria-controls={`${props.id}-1`}
          >
            {props.title1}
          </button>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <button
            className="dropdown-item"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${props.id}-2`}
            aria-controls={`#${props.id}-2`}
          >
            {props.title2}
          </button>
        </li>
      </ul>
    </div>
  );
}

export default DropdownButton;
