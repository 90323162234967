function CollapseButton(props) {
  const tempID = `#${props.id}`;
  return (
    <div>
      <button
        key={props.id}
        style={{ width: "100%" }}
        className="nes-btn is-warning"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={tempID}
        aria-expanded={props.displayOnLoad ? "true" : "false"}
        aria-controls={props.id}
      >
        {props.title}
      </button>
    </div>
  );
}

export default CollapseButton;
