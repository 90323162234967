import { useLoaderData, useNavigate } from "react-router-dom";
import React, { useState, useReducer, useEffect } from "react";
import validateAnswer from "../functions/ValidateAnswer";
import reducer from "../functions/reducer";
import Timer from "./Timer";

function GenitiveCaseQuiz() {
  const navigate = useNavigate();
  const [quizActive, setQuizActive] = useState(true);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [submitBtnText, setSubmitBtnText] = useState("Deimhnigh");
  const [userScore, dispatch] = useReducer(reducer, {
    correct: null,
    answered: null,
  });

  const data = useLoaderData();

  const [enteredAnswer, setEnteredAnswer] = useState("");

  const [currentIndex, setCurrentIndex] = useState(0);
  const question = data.list[currentIndex];
  const lastIndex = data.list.length - 1;

  const url = data.url;
  useEffect(() => {
    if (quizActive) return dispatch({ type: "RESET" });
    if (!quizActive)
      return navigate("/quiz-críochnaithe", {
        state: { score: userScore, url: url },
      });
  }, [quizActive]);

  function submitAnswerHandler(event) {
    event.preventDefault();
    if (correctAnswer === "") {
      if (
        validateAnswer(
          enteredAnswer.trim(),
          question.genitiveCase
        )
      ) {
        dispatch({ type: "CORRECT" });
        if (currentIndex === lastIndex) {
          setCurrentIndex(0);
        } else {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }
      } else {
        setCorrectAnswer(question.genitiveCase);
        setSubmitBtnText("->");
        dispatch({ type: "INCORRECT" });
      }
    } else {
      setCorrectAnswer("");
      setSubmitBtnText("Deimhnigh");
      if (currentIndex === lastIndex) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    }

    setEnteredAnswer("");
  }

  function inputChangeHandler(event) {
    setEnteredAnswer(event.target.value);
  }

  return (
    <div
      className="active-quiz-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div className="card">
        <div className="card-header">
          <Timer setQuizStatus={setQuizActive} />
          <div className="title-temp-container">
            <h1>An Tuiseal Ginideach</h1>
          </div>
        </div>
        <div className="card-body center-content">
          <span>
            cuir an t-ainmfhocal thíos sa tuiseal ginideach {question.quantity}
          </span>
          <span>*gan an t-alt*</span>
          <span style={{ fontWeight: "bold", color: "red" }}>
            {correctAnswer}
          </span>
          <form
            onSubmit={submitAnswerHandler}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <label htmlFor="name_field">{question.noun}</label>
            <span className="question">{question.sentence}</span>
            <input
              autoComplete="off"
              value={enteredAnswer}
              onChange={inputChangeHandler}
              type="text"
              id="name_field"
              className="nes-input"
            />
            <button className="nes-btn is-success">{submitBtnText}</button>
          </form>
        </div>
        <div className="card-footer center-content">
          <span>
            {userScore.answered
              ? userScore.correct
                ? `${userScore.correct}/${userScore.answered}`
                : `0/${userScore.answered}`
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
}

export default GenitiveCaseQuiz;
