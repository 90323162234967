import { useLoaderData, useNavigate } from "react-router-dom";
import React, { useState, useReducer, useEffect } from "react";
import validateAnswer from "../functions/ValidateAnswer";
import reducer from "../functions/reducer";
import Timer from "./Timer";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Popover from "react-bootstrap/Popover";

/* state should not be included in useEffect functions, however Im not sure if i should omit it
or not 
*/

function NounsQuiz() {
  const navigate = useNavigate();
  const [quizActive, setQuizActive] = useState(true);
  const [userScore, dispatch] = useReducer(reducer, {
    correct: null,
    answered: null,
  });

  const data = useLoaderData();
  const url = data.url;
  useEffect(() => {
    if (quizActive) return dispatch({ type: "RESET" });
    if (!quizActive)
      return navigate("/quiz-críochnaithe", {
        state: { score: userScore, url: url },
      });
  }, [quizActive]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const question = data.nouns[currentIndex];
  const lastIndex = data.nouns.length - 1;

  // useEffect(() => {
  //   //init tooltip

  //   const x = document.querySelector('span[data-bs-toggle="tooltip"]');
  //   let y = new Tooltip(x);

  //   return () => {
  //     y.dispose();
  //   };
  // },[currentIndex]);
  // data-bs-toggle="tooltip"
  //           data-bs-placement="top"
  //           title={question.translation}

  function mascClkHandler() {
    if (validateAnswer("firinscneach", question.gender)) {
      dispatch({ type: "CORRECT" });
    } else {
      dispatch({ type: "INCORRECT" });
    }
    //if index is end of array start again
    if (currentIndex === lastIndex) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prev) => prev + 1);
    }
  }
  function femClkHandler() {
    if (validateAnswer("baininscneach", question.gender)) {
      dispatch({ type: "CORRECT" });
    } else {
      dispatch({ type: "INCORRECT" });
    }
    //if index is end of array start again
    if (currentIndex === lastIndex) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prev) => prev + 1);
    }
  }

  return (
    <div className="active-quiz-container center-content">
      <div className="card">
        <div className="card-header">
          <Timer setQuizStatus={setQuizActive} />
          <div className="title-temp-container">
            <span>
              An bhfuil an t-ainmfhocal seo firinscneach nó baininscneach?
            </span>
          </div>
        </div>

        <div className="card-body center-content">
          <OverlayTrigger
            trigger={["hover", "focus"]}
            key="top"
            placement="top"
            overlay={
              <Tooltip>
                <span>{question.translation}</span>
              </Tooltip>
            }
          >
            <span className="question">{question.noun}</span>
          </OverlayTrigger>
          <div>
            <button onClick={mascClkHandler} className="nes-btn is-warning">
              Firinscneach
            </button>
            <button onClick={femClkHandler} className="nes-btn is-warning">
              Baininscneach
            </button>
          </div>
        </div>
        <div className="card-footer center-content">
          <span>
            {userScore.answered
              ? userScore.correct
                ? `${userScore.correct}/${userScore.answered}`
                : `0/${userScore.answered}`
              : ""}
          </span>
        </div>
      </div>
    </div>
  );
}

export default NounsQuiz;
