import DropdownButton from "../components/DropdownButton";
import CollapseElement from "../components/CollapseElement";
import CollapseButton from "../components/CollapseButton";
import { useLoaderData } from "react-router-dom";

function GenitiveCaseExplanationPage() {
  let displaySmallText;
  if (window.innerWidth < 700) {
    displaySmallText = true;
  } else {
    displaySmallText = false;
  }
  const data = useLoaderData();
  const generalRulesContent = { __html: data.generalRules };

  const values = [
    {
      id: "dec-1",
      lgBtnTitle: "Díochlaonadh 1",
      smBtnTitle: "Grúpa 1",
      displayOnLoad: true,
      content: [
        { id: "dec-1-1", rules: { __html: data.firstDeclSing } },
        { id: "dec-1-2", rules: { __html: data.firstDeclPlur } },
      ],
    },
    {
      id: "dec-2",
      lgBtnTitle: "Díochlaonadh 2",
      smBtnTitle: "Grúpa 2",
      displayOnLoad: true,
      content: [
        { id: "dec-2-1", rules: { __html: data.secondDeclSing } },
        { id: "dec-2-2", rules: { __html: data.secondDeclPlur } },
      ],
    },
    {
      id: "dec-3",
      lgBtnTitle: "Díochlaonadh 3",
      smBtnTitle: "Grúpa 3",
      displayOnLoad: true,
      content: [
        { id: "dec-3-1", rules: { __html: data.thirdDeclSing } },
        { id: "dec-3-2", rules: { __html: data.thirdDeclPlur } },
      ],
    },
    {
      id: "dec-4",
      lgBtnTitle: "Díochlaonadh 4",
      smBtnTitle: "Grúpa 4",
      displayOnLoad: true,
      content: [
        { id: "dec-4-1", rules: { __html: data.fourthDeclSing } },
        { id: "dec-4-2", rules: { __html: data.fourthDeclPlur } },
      ],
    },
    {
      id: "dec-5",
      lgBtnTitle: "Díochlaonadh 5",
      smBtnTitle: "Grúpa 5",
      displayOnLoad: true,
      content: [
        { id: "dec-5-1", rules: { __html: data.fifthDeclSing } },
        { id: "dec-5-2", rules: { __html: data.fifthDeclPlur } },
      ],
    },
  ];

  return (
    <div className="grammar-explanation-page-container">
      <div className="row-1-with-6-buttons">
        <CollapseButton
          key="gen-rules"
          id="gen-rules"
          title={displaySmallText ? "R. Gin" : "Rialacha Ginearálta"}
          displayOnLoad={true}
        />
        {values.map((elm) => {
          return (
            <DropdownButton
              key={elm.id}
              id={elm.id}
              btnTitle={displaySmallText ? elm.smBtnTitle : elm.lgBtnTitle}
              title1="uatha"
              title2="iolra"
            />
          );
        })}
      </div>

      <div id="accordion-collapse" className="nes-container is-rounded row-2">
        <CollapseElement
          key="gen-rules"
          id="gen-rules"
          content={generalRulesContent}
          displayOnLoad={true}
          collapseParent="#accordion-collapse"
        />
        {values.map((elm) => {
          return (
            <>
              <CollapseElement
                key={elm.content[0].id}
                id={elm.content[0].id}
                content={elm.content[0].rules}
                collapseParent="#accordion-collapse"
              />
              <CollapseElement
                key={elm.content[1].id}
                id={elm.content[1].id}
                content={elm.content[1].rules}
                collapseParent="#accordion-collapse"
              />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default GenitiveCaseExplanationPage;
