function CollapseElement(props) {
  return (
    <div
      key={props.id}
      data-bs-parent={props.collapseParent ? props.collapseParent : ""}
      className={props.displayOnLoad ? "collapse show" : "collapse"}
      id={props.id}
    >
      <div dangerouslySetInnerHTML={props.content}></div>
    </div>
  );
}

export default CollapseElement;
