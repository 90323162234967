import { json } from "react-router-dom";

export async function quizSettingsLoader({ request, params }) {
  const arr = request.url.split("/");
  const topic = arr[arr.length - 2];

  const response = await fetch("/quizSettings.json");

  if (response.ok) {
    const j = await response.json(); //js obj

    return json(j[topic], { status: 200 });
  } else {
    return null;
  }
}
