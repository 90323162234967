import QuizForm from "../components/QuizForm";

import { useLoaderData, useLocation } from "react-router-dom";

function QuizConfigPage() {
  const loaderData = useLoaderData();
  return (
    <div className="config-page-container center-content">
      <QuizForm settings={loaderData} />
    </div>
  );
}

export default QuizConfigPage;
