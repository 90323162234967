function QuizFormElement(props) {
  const labels = props.labels.map((label) => (
    <label key={Math.random()}>
      <input
        type={props.canSelectMultiple ? "checkbox" : "radio"}
        className={props.canSelectMultiple ? "nes-checkbox" : "nes-radio"}
        name={props.identifier}
        value={label.english ? label.english : label}
      />
      <span>{label.irish ? label.irish : label}</span>
    </label>
  ));

  /* <div>
  <label htmlFor="name_field">Your name</label>
  <input type="number" id="name_field" className="nes-input" />
</div> */

  return (
    <div className="center-content">
      <span className="title eight-bit-font">{props.title}</span>
      <div className="quiz-form-labels">{labels}</div>
    </div>
  );
}

export default QuizFormElement;
