import { Link } from "react-router-dom";
import logoCogg from "../assets/cogg-logo.png";
import NavBar from "../components/NavBar";
import { Outlet } from "react-router-dom";
import "../../src/general.css";

function RootLayout() {
  return (
    <div className="layout-container">
      <header>
        <NavBar />
      </header>
      <main>
        <Outlet />
      </main>
      <footer
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link to="https://www.cogg.ie/">
          <img style={{ height: "120px" }} src={logoCogg} alt="Logo COGG" />
        </Link>
      </footer>
    </div>
  );
}

export default RootLayout;
