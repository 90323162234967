import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faPencil } from "@fortawesome/free-solid-svg-icons";

function ArticleLandingPage() {
  return (
    <div className="landing-page-container center-content">
      <h1>An t-Alt</h1>
      <div className="nes-container is-rounded">
        <h2>Roghnaigh...</h2>
        <div className="buttons">
          <div className="dropdown">
            <button
              className="nes-btn is-success "
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Míniú</span>
              <FontAwesomeIcon icon={faBook} />
            </button>
            <ul className="dropdown-menu">
              <li>
                <span className="dropdown-item-text">An t-Alt agus...</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link
                  to="/an-talt/tuiseal-ainmneach/míniú"
                  className="dropdown-item"
                >
                  An Tuiseal Ainmneach
                </Link>
              </li>
              <li>
                <Link
                  to="/an-talt/tuiseal-ginideach/míniú"
                  className="dropdown-item"
                >
                  An Tuiseal Ginideach
                </Link>
              </li>
            </ul>
          </div>
          <div className="dropdown">
            <button
              className="nes-btn is-success "
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Quiz</span>
              <FontAwesomeIcon icon={faPencil} />
            </button>
            <ul className="dropdown-menu">
              <li>
                <span className="dropdown-item-text">An t-Alt agus...</span>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <Link to="/an-talt/an-talt-ta-quiz" className="dropdown-item">
                  An Tuiseal Ainmneach
                </Link>
              </li>
              <li>
                <Link to="/an-talt/an-talt-tg-quiz" className="dropdown-item">
                  An Tuiseal Ginideach
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleLandingPage;
