import { Link } from "react-router-dom";
function DisplayError() {
  return (
    <div style={{height: "100vh"}}>
      <div
        className="center-content eight-bit-font"
        style={{ flexDirection: "column" }}
      >
        <h1>404</h1>
        <h2>Caillte?</h2>
        <Link className="nes-btn is-warning" to="/">
          Baile
        </Link>
      </div>
    </div>
  );
}

export default DisplayError;
