import { json } from "react-router-dom";

export async function articleQuizLoader({ request }) {
  const url = new URL(request.url);
  const quizType = `nouns-${url.pathname.split('/')[2].split('-')[2]}`;
  

  const response = await fetch("/definiteArticleData.json");

  const j = await response.json();
  const nouns = j[quizType].sort((a, b) => {
    return Math.floor(Math.random() * 3) - 1;
  });
  const data = { nouns: nouns, url: url };
  return json(data, { status: 200 });
}
