export default function configureTitle(address) {
  switch (address) {
    case "briathra":
      return "Réimniú Briathair";

    case "ainmfhocail":
      return "Inscne Ainmfhocail";

    case "alt-cinnte":
      return "An tAlt Cinnte";

    case "tuiseal-ginideach":
      return "An Tuiseal Ginideach";
  }
}
