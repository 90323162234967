import ReactGA from "react-ga4";
import { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/RootLayout";
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import DisplayError from "./pages/DisplayError";
import LandingPage from "./pages/LandingPage";
import ArticleLandingPage from "./pages/ArticleLandingPage";
import NounExplanationPage from "./pages/NounExplanationPage";
import ArticleNominativeExplanationPage from "./pages/ArticleNominativeExplanationPage";
import ArticleGenitiveExplanationPage from "./pages/ArticleGenitiveExplanationPage";
import GenitiveCaseExplanationPage from "./pages/GenitiveCaseExplanationPage";
import GenitiveCaseQuiz from "./components/GenitiveCaseQuiz";
import VerbExplanationPage from "./pages/VerbExplanationPage";
import QuizConfigPage from "./pages/QuizConfigPage";
import VerbsQuiz from "./components/VerbsQuiz";
import NounsQuiz from "./components/NounsQuiz";
import QuizOver from "./pages/QuizOver";
import { verbExplanationLoader } from "./loaders/verbExplanationLoader";
import { nounExplanationLoader } from "./loaders/nounExplanationLoader";
import { genitiveCaseExplanationLoader } from "./loaders/genitiveCaseExplanationLoader";
import { articleNominativeExplanationLoader } from "./loaders/articleNominativeExplanationLoader";
import { articleGenitiveExplanationLoader } from "./loaders/articleGenitiveExplanationLoader";
import { quizSettingsLoader } from "./loaders/quizSettingsLoader";
import { verbQuizLoader } from "./loaders/verbQuizLoader";
import { genitiveCaseQuizLoader } from "./loaders/genitiveCaseQuizLoader";
import { nounQuizLoader } from "./loaders/nounQuizLoader";
import { articleQuizLoader } from "./loaders/articleQuizLoader";
import ArticleTGQuiz from "./components/ArticleTGQuiz";
import ArticleTAQuiz from "./components/ArticleTAQuiz";

const TRACKING_ID = "G-S96QS9284F"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <DisplayError />,
    children: [
      { index: true, element: <HomePage /> },
      { path: "/cé-muid", element: <AboutPage /> },
      { path: "/briathra", element: <LandingPage /> },
      { path: "/ainmfhocail", element: <LandingPage /> },
      { path: "/an-talt", element: <ArticleLandingPage /> },
      { path: "/quiz-críochnaithe", element: <QuizOver /> },
      { path: "/tuiseal-ginideach", element: <LandingPage /> },
      {
        path: "/briathra/míniú",
        element: <VerbExplanationPage />,
        loader: verbExplanationLoader,
      },
      {
        path: "/ainmfhocail/míniú",
        element: <NounExplanationPage />,
        loader: nounExplanationLoader,
      },
      {
        path: "/an-talt/tuiseal-ainmneach/míniú",
        element: <ArticleNominativeExplanationPage />,
        loader: articleNominativeExplanationLoader,
      },
      {
        path: "/an-talt/tuiseal-ginideach/míniú",
        element: <ArticleGenitiveExplanationPage />,
        loader: articleGenitiveExplanationLoader,
      },
      {
        path: "/tuiseal-ginideach/míniú",
        element: <GenitiveCaseExplanationPage />,
        loader: genitiveCaseExplanationLoader,
      },
      {
        path: "/briathra/config",
        element: <QuizConfigPage />,
        loader: quizSettingsLoader,
      },
      {
        path: "/briathra/briathra-quiz",
        element: <VerbsQuiz />,
        loader: verbQuizLoader,
      },
      {
        path: "/tuiseal-ginideach/tuiseal-ginideach-quiz",
        element: <GenitiveCaseQuiz />,
        loader: genitiveCaseQuizLoader,
      },
      {
        path: "/ainmfhocail/ainmfhocail-quiz",
        element: <NounsQuiz />,
        loader: nounQuizLoader,
      },
      {
        path: "/an-talt/an-talt-tg-quiz",
        element: <ArticleTGQuiz />,
        loader: articleQuizLoader,
      },
      {
        path: "/an-talt/an-talt-ta-quiz",
        element: <ArticleTAQuiz />,
        loader: articleQuizLoader,
      },
    ],
  },
]);

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: window.location.pathname + window.location.search,
    });
  }, []);

  return <RouterProvider router={router} />;
}

export default App;
