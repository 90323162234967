import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faPencil } from "@fortawesome/free-solid-svg-icons";
import configureTitle from "../functions/configureTitle";

/* I think the whole container for the buttons for quiz and explanation should be their own component 
    should i use state or just a constant for location?
*/

function LandingPage() {
  const pathSegment = useLocation().pathname.slice(1);

  let title = configureTitle(pathSegment);

  return (
    <div className="landing-page-container center-content">
      <h1>{title}</h1>
      <div className="nes-container is-rounded">
        <h2>Roghnaigh...</h2>
        <div className="buttons">
          <Link className="nes-btn is-success" to={`/${pathSegment}/míniú`}>
            <span>Míniú</span>
            <FontAwesomeIcon icon={faBook} />
          </Link>
          <Link
            className="nes-btn is-success"
            to={
              pathSegment === "briathra"
                ? "config"
                : `/${pathSegment}/${pathSegment}-quiz`
            }
          >
            <span>Quiz</span>
            <FontAwesomeIcon icon={faPencil} />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
