import CollapseButton from "../components/CollapseButton";
import CollapseElement from "../components/CollapseElement";
import { useLoaderData } from "react-router-dom";

function ArticleGenitiveExplanationPage() {
  let displaySmallText;
  if (window.innerWidth < 700) {
    displaySmallText = true;
  } else {
    displaySmallText = false;
  }
  const data = useLoaderData();

  const values = [
    {
      id: "generalRules",
      displayOnLoad: true,
      lgBtnTitle: "Rialacha Ginearálta",
      smBtnTitle: "Rialacha Gin.",
      content: { __html: data.generalRules },
    },
    {
      id: "female",
      displayOnLoad: false,
      lgBtnTitle: "Baininscneach",
      smBtnTitle: "Bain.",
      content: { __html: data.feminineRules },
    },
    {
      id: "male",
      displayOnLoad: false,
      lgBtnTitle: "Firinscneach",
      smBtnTitle: "Firin.",
      content: { __html: data.masculineRules },
    },
  ];

  return (
    <div className=" grammar-explanation-page-container">
      <div className="row-1-with-3-buttons">
        {values.map((elm) => {
          return (
            <CollapseButton
              key={elm.id}
              id={elm.id}
              title={displaySmallText ? elm.smBtnTitle : elm.lgBtnTitle}
              displayOnLoad={elm.displayOnLoad}
            />
          );
        })}
      </div>

      <div className="nes-container is-rounded row-2">
        {values.map((elm) => {
          return (
            <CollapseElement
              key={elm.id}
              id={elm.id}
              content={elm.content}
              displayOnLoad={elm.displayOnLoad}
            />
          );
        })}
      </div>
    </div>
  );
}

export default ArticleGenitiveExplanationPage;
