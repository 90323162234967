import QuizFormElement from "./QuizFormElement";
import { useLocation } from "react-router-dom";
import configureTitle from "../functions/configureTitle";

function QuizForm(props) {
  const addressArray = useLocation().pathname.split("/");
  const currentPath = addressArray[addressArray.length - 2];
  const title = configureTitle(currentPath);

  const settings = props.settings;
  const formSectionsList = settings.formSections.map((section) => (
    <QuizFormElement key={Math.random()} {...section} />
  ));

  /* Don't forget to add default values on the inputs and also to check whether a form is valid before
  submitting */

  return (
    <>
      <h1>{title}</h1>

      <form
        className="center-content"
        action={`/${currentPath}/${currentPath}-quiz`}
        method="get"
      >
        <div className="form-sections nes-container is-rounded">
          {formSectionsList}
        </div>

        <button type="submit" className="nes-btn is-warning">
          Tosaigh!
        </button>
      </form>
    </>
  );
}

export default QuizForm;
