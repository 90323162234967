import { Link, useLocation } from "react-router-dom";
import calculateScore from "../functions/calculateScore";

function QuizOver() {
  const location = useLocation();
  const score = location.state.score;
  const url = location.state.url;

  const finalScore = calculateScore(score.correct, score.answered);

  return (
    <div className="quiz-over-container center-content">
      <div className="nes-container center-content">
        <div className="icons">
          <i className="nes-icon trophy is-large"></i>
          <h2>Críochnaithe</h2>
          <i className="nes-icon trophy is-large"></i>
        </div>
        <span className="score">{finalScore}</span>
        <div>
          <Link to="/" className="nes-btn">
            Baile
          </Link>
          <Link to={url} className="nes-btn is-primary">
            Tosaigh arís
          </Link>
        </div>
      </div>
    </div>
  );
}

export default QuizOver;
