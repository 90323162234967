export async function nounExplanationLoader() {
  const responseGeneralRules = await fetch("/nouns/nounsGeneralRule.html");
  const generalRules = await responseGeneralRules.text();

  const responseFeminineRules = await fetch("/nouns/feminineRules.html");
  const feminineRules = await responseFeminineRules.text();

  const responseMasculineRules = await fetch("/nouns/masculineRules.html");
  const masculineRules = await responseMasculineRules.text();

  const data = { generalRules, feminineRules, masculineRules };
  return data;
}
