import CollapseButton from "../components/CollapseButton";
import CollapseElement from "../components/CollapseElement";
import DropdownButton from "../components/DropdownButton";
import { useLoaderData } from "react-router-dom";

function VerbExplanationPage() {
  let displaySmallText;
  if (window.innerWidth < 700) {
    displaySmallText = true;
  } else {
    displaySmallText = false;
  }
  const data = useLoaderData();
  const generalRules = {
    id: "generalRules",
    lgBtnTitle: "Rialacha Ginearálta",
    smBtnTitle: "Rialacha Gin.",
    displayOnLoad: true,
    content: { __html: data.generalRules },
  };
  const values = [
    {
      id: "pastTense",
      lgBtnTitle: "Aimsir Chaite",
      smBtnTitle: "A.C",
      displayOnLoad: false,
      regContent: { __html: data.pastTenseReg },
      irrContent: { __html: data.pastTenseIrr },
    },
    {
      id: "presentTense",
      lgBtnTitle: "Aimsir Láithreach",
      smBtnTitle: "A.L",
      displayOnLoad: false,
      regContent: { __html: data.presentTenseReg },
      irrContent: { __html: data.presentTenseIrr },
    },
    {
      id: "futureTense",
      lgBtnTitle: "Aimsir Fháistineach",
      smBtnTitle: "A.F",
      displayOnLoad: false,
      regContent: { __html: data.futureTenseReg },
      irrContent: { __html: data.futureTenseIrr },
    },
    {
      id: "conditionalTense",
      lgBtnTitle: "Modh Coinníollach",
      smBtnTitle: "Modh C",
      displayOnLoad: false,
      regContent: { __html: data.conditionalTenseReg },
      irrContent: { __html: data.conditionalTenseIrr },
    },
  ];

  return (
    <div className="grammar-explanation-page-container">
      <div className="row-1-with-5-buttons">
        <CollapseButton
          key={generalRules.id}
          id={generalRules.id}
          title={
            displaySmallText ? generalRules.smBtnTitle : generalRules.lgBtnTitle
          }
          displayOnLoad={generalRules.displayOnLoad}
        />
        {values.map((elm) => {
          return (
            <DropdownButton
              key={elm.id}
              id={elm.id}
              btnTitle={displaySmallText ? elm.smBtnTitle : elm.lgBtnTitle}
              title1="Rialta"
              title2="Neamhrialta"
            />
          );
        })}
      </div>

      <div id="accordion-collapse" className="nes-container is-rounded row-2">
        <CollapseElement
          key={generalRules.id}
          id={generalRules.id}
          content={generalRules.content}
          displayOnLoad={generalRules.displayOnLoad}
          collapseParent="#accordion-collapse"
        />
        {values.map((elm) => {
          return (
            <>
              <CollapseElement
                key={`${elm.id}-1`}
                id={`${elm.id}-1`}
                content={elm.regContent}
                displayOnLoad={elm.displayOnLoad}
                collapseParent="#accordion-collapse"
              />
              <CollapseElement
                key={`${elm.id}-2`}
                id={`${elm.id}-2`}
                content={elm.irrContent}
                displayOnLoad={elm.displayOnLoad}
                collapseParent="#accordion-collapse"
              />
            </>
          );
        })}
      </div>
    </div>
  );
}

export default VerbExplanationPage;
