export async function verbExplanationLoader(){

    const responseGeneralRules= await fetch("/verbs/verbsGeneralRule.html");
    const generalRules = await responseGeneralRules.text();
    
    const responsePastTenseReg = await fetch("/verbs/pastTenseReg.html");
    const pastTenseReg = await responsePastTenseReg.text();
    const responsePastTenseIrr = await fetch("/verbs/pastTenseIrr.html");
    const pastTenseIrr = await responsePastTenseIrr.text();

    const responsePresentTenseReg = await fetch("/verbs/presentTenseReg.html");
    const presentTenseReg = await responsePresentTenseReg.text();
    const responsePresentTenseIrr = await fetch("/verbs/presentTenseIrr.html");
    const presentTenseIrr = await responsePresentTenseIrr.text();

    const responseFutureTenseReg = await fetch("/verbs/futureTenseReg.html");
    const futureTenseReg = await responseFutureTenseReg.text();
    const responseFutureTenseIrr = await fetch("/verbs/futureTenseIrr.html");
    const futureTenseIrr = await responseFutureTenseIrr.text();

    const responseConditionalTenseReg = await fetch("/verbs/conditionalTenseReg.html");
    const conditionalTenseReg = await responseConditionalTenseReg.text();
    const responseConditionalTenseIrr = await fetch("/verbs/conditionalTenseIrr.html");
    const conditionalTenseIrr = await responseConditionalTenseIrr.text();

    const data = {generalRules, pastTenseReg, pastTenseIrr, presentTenseReg, presentTenseIrr, futureTenseReg, futureTenseIrr, conditionalTenseReg, conditionalTenseIrr };
    return data;
};