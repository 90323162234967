import { json } from "react-router-dom";

export async function genitiveCaseQuizLoader({ request }) {
  const url = new URL(request.url);

  const response = await fetch("/genitiveCaseData.json");

  const j = await response.json();
  const list = j.list.sort((a, b) => {
    return Math.floor(Math.random() * 3) - 1;
  });
  const data = { list: list, url: url };
  return json(data, { status: 200 });
}
