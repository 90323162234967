import { json } from "react-router-dom";

export async function nounQuizLoader({ request, params }) {
  const url = new URL(request.url);

  const response = await fetch("/nounData.json"); //this is a response obj

  const j = await response.json(); //take the body of the rsponse and turn into js obj
  const nouns = j.nouns.sort((a, b) => {
    return Math.floor(Math.random() * 3) - 1;
  });
  const data = { nouns: nouns, url: url };
  return json(data, { status: 200 }); //response.json() extracts the body of response and turns it in js obj
  // return json is actually turning it into a response obj
}
