export async function genitiveCaseExplanationLoader(){
    
    const responseFirstDeclSing= await fetch("/genitiveCase/firstDeclensionSingular.html");
    const firstDeclSing = await responseFirstDeclSing.text();
    const responseFirstDeclPlur= await fetch("/genitiveCase/firstDeclensionPlural.html");
    const firstDeclPlur = await responseFirstDeclPlur.text();
    
    const responseSecondDeclSing= await fetch("/genitiveCase/secondDeclensionSingular.html");
    const secondDeclSing = await responseSecondDeclSing.text();
    const responseSecondDeclPlur= await fetch("/genitiveCase/secondDeclensionPlural.html");
    const secondDeclPlur = await responseSecondDeclPlur.text();

    const responseThirdDeclSing= await fetch("/genitiveCase/thirdDeclensionSingular.html");
    const thirdDeclSing = await responseThirdDeclSing.text();
    const responseThirdDeclPlur= await fetch("/genitiveCase/thirdDeclensionPlural.html");
    const thirdDeclPlur = await responseThirdDeclPlur.text();

    const responseFourthDeclSing= await fetch("/genitiveCase/fourthDeclensionSingular.html");
    const fourthDeclSing = await responseFourthDeclSing.text();
    const responseFourthDeclPlur= await fetch("/genitiveCase/fourthDeclensionPlural.html");
    const fourthDeclPlur = await responseFourthDeclPlur.text();

    const responseFifthDeclSing= await fetch("/genitiveCase/fifthDeclensionSingular.html");
    const fifthDeclSing = await responseFifthDeclSing.text();
    const responseFifthDeclPlur= await fetch("/genitiveCase/fifthDeclensionPlural.html");
    const fifthDeclPlur = await responseFifthDeclPlur.text();

    const responseGeneralRules= await fetch("/genitiveCase/generalRules.html");
    const generalRules = await responseGeneralRules.text();
    

    const data = {generalRules, firstDeclSing, firstDeclPlur, secondDeclSing, secondDeclPlur, thirdDeclSing, thirdDeclPlur, fourthDeclSing, fourthDeclPlur, fifthDeclSing, fifthDeclPlur };
    return data;
};