import backgroundImage from "../assets/grama-bg.png";
import ContentNavBar from "../components/ContentNavBar";

function HomePage() {
  return (
    <div className="home-page-container">
      <div className="center-content">
        <img
          className="background-map-image"
          src={backgroundImage}
          alt="map of Ireland"
        />
      </div>
      <div className="center-content">
        <ContentNavBar />
      </div>
    </div>
  );
}
export default HomePage;
