import { useLocation, Link } from "react-router-dom";

function NavBar() {
  
  return (
    <nav className="navbar navbar-expand-sm  nes-container is-rounded eight-bit-font" style={{borderWidth : "8px"}}>
      <div className="container-fluid">
        <Link to="/" className="navbar-brand">
         Grama Gasta
        </Link>
        <button
          className="navbar-toggler nes-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarNavAltMarkup"
        >
          <div className="navbar-nav">
            <Link to="/cé-muid" className="nav-link nes-btn">
              Cé Muid
            </Link>
            <Link to="/" className="nav-link nes-btn">
              Baile
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
