import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function ContentNavBar() {
  return (
    <div className="content-navbar">
      <OverlayTrigger
        trigger={["hover", "focus"]}
        key="top"
        placement="top"
        overlay={
          <Tooltip>
            <span>Verbs</span>
          </Tooltip>
        }
      >
        <Link className="nes-btn" to="/briathra">
          Briathra
        </Link>
      </OverlayTrigger>

      <OverlayTrigger
        trigger={["hover", "focus"]}
        key="right1"
        placement="right"
        overlay={
          <Tooltip>
            <span>Gender of nouns</span>
          </Tooltip>
        }
      >
        <Link className="nes-btn" to="/ainmfhocail">
          Inscne Ainmfhocail
        </Link>
      </OverlayTrigger>

      <OverlayTrigger
        trigger={["hover", "focus"]}
        key="right2"
        placement="right"
        overlay={
          <Tooltip>
            <span>Definite article</span>
          </Tooltip>
        }
      >
        <Link className="nes-btn" to="/an-talt">
          An tAlt
        </Link>
      </OverlayTrigger>

      <OverlayTrigger
        trigger={["hover", "focus"]}
        key="bottom"
        placement="bottom"
        overlay={
          <Tooltip>
            <span>The genitive case</span>
          </Tooltip>
        }
      >
        <Link className="nes-btn" to="/tuiseal-ginideach">
          Tuiseal ginideach
        </Link>
      </OverlayTrigger>
    </div>
  );
}

export default ContentNavBar;

<OverlayTrigger
  trigger={["hover", "focus"]}
  key="top"
  placement="top"
  overlay={
    <Tooltip>
      <span></span>
    </Tooltip>
  }
></OverlayTrigger>;
