import { useEffect, useState } from "react";

function Timer(props) {
  const setQuizActive = props.setQuizStatus;
  const [seconds, setSeconds] = useState(60);

  useEffect(() => {
    let tempNum = seconds;
    const interval = setInterval(() => {
      if (tempNum === 0) {
        setQuizActive(false);
        return clearInterval(interval);
      } else {
        setSeconds((prev) => prev - 1);
        tempNum--;
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

 
  let minutes = Math.floor(seconds / 60);
  let s = `${seconds % 60}`.padStart(2, "0");

  return (
    <div
      className="nes-container is-rounded"
      style={{ backgroundColor: "#e93800", borderWidth: "5px", width: "100px" }}
    >
      {minutes}:{s}
    </div>
  );
}

export default Timer;
